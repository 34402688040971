import $axios from '@/utils/request'

// 获取员工列表
export function getListEmployee(data) {
    const url = '/merchant/user/listAllEmployee'
    return $axios.fGet(url, data)
}

// 新增员工
export function addSta(data) {
    const url = '/merchant/user/addEmployee'
    return $axios.fPost(url, data)
}

// 编辑员工
export function editSta(data) {
    const url = '/merchant/user/editEmployee'
    return $axios.fPost(url, data)
}

// 用户列表
export function getUserList(data) {
    const url = '/merchant/user/list'
    return $axios.fPost(url, data)
}

// 获取子部门列表
export function getchildDp(data) {
    const url = '/dingtalk/getchildDp'
    return $axios.fGet(url, data)
}

// 获取招生来源列表
export function getListEnrollmentSource(data) {
    const url = '/merchant/user/listEnrollmentSource'
    return $axios.fGet(url, data)
}

// 批量访客登记

export function addGuestBat(data) {
    const url = '/merchant/user/addGuestBat'
    return $axios.fPost(url, data)
}

//同步钉钉用户列表
export function bindUserId(data) {
    const url = '/dingtalk/bindUserId'
    return $axios.fGet(url, data)
}

//绑定钉钉用户
export function dingtalkBind(data) {
    const url = '/dingtalk/bind'
    return $axios.fGet(url, data)
}

//获取钉钉用户列表
export function getDingtalkUserList(data) {
    const url = '/dingtalk/getDingtalkUserList'
    return $axios.fPost(url, data)
}

// 获取职位列表
export function getPositionList(data) {
    const url = '/dingtalk/getPositionList'
    return $axios.fGet(url, data)
}
